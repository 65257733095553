import PropTypes from 'prop-types'
import React from 'react';
// import Link from 'gatsby';

class TransactionShareShort extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      trname: '',
      price: '',
      sellerName: '',
      sellerEmail: '',
      humanId:'',
      trhash: '',
      fee: 0,
      total: 0,
      shareButton : '#',
      error:false,
      errorMessage: '',
    };
  }

  componentDidMount () {
    console.log('component TransactionEdit From mounted');
    let trhash = this.props.path.hash;

    let requestUrl = `${process.env.GATSBY_TRUSTA_API_URL}/get-transaction-share?short=${trhash.substr(1)}`
    fetch(requestUrl, {
      method: 'GET', 
      mode: 'cors', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    }).then((response) => {
      return response.json();
    }).then((data) => {
      if (data.status === 'success'){
        this.setState({
          trname : data.data.trname, 
          price: data.data.price,
          sellerName: data.data.sellerName,
          sellerEmail: data.data.sellerEmail,
          humanId: data.data.humanId,
          fee: data.data.fee,
          total: data.data.total,
          shareButton: data.data.shareButton
        });
      }
      else{
        this.setState({
          error: true,
          errorMessage: data.message,
        });
        //window.location = "https://t.me/TrastaTestingBot"
      }
    });
  }
 
  
  render() {
    
    return (
      <header id="header" style={this.props.timeout ? { display: 'none' } : {}}>
      <div className="logo">
        <span className="icon fa-handshake-o fa-2x"></span>
      </div>
      <div className="content">
        <div className="inner" style={ this.state.error ? {display:"none"}: {display:"block"} } >       
          <p>
            <h4>Завдаток № {this.state.humanId }</h4>
          </p>
          <p>
            <strong> Товар/Послуга : {this.state.trname } </strong>
          </p>
          <p>
            <strong> Сума {this.state.total } грн. </strong>
            <br/>
            <small> в т.ч. Комісія сервісу { this.state.fee } грн. </small>
          </p>
          <p>
            <a className="button" href={this.state.shareButton}><i className="fa fa-telegram"></i> Перейти </a>
          </p>
        </div>

        <div className="inner" style={ this.state.error ? {display:"block"}: {display:"none"} }>       
          <p className="error">
            {/* <h3> <i class="fa fa-exclamation-triangle" aria-hidden="true"></i> Помилка</h3> */}
          </p>
          <p className="error">
            <h5>{this.state.errorMessage }</h5>
          </p>
          <p className="error">
            <a className="button" style={{marginRight:"0.5rem"}}href={`https://t.me/${process.env.GATSBY_TELEGRAM_BOT}`}>Повернутись</a>
          </p>
        </div>

      </div>

    </header>
    )
  }
}
TransactionShareShort.propTypes = {
  onOpenArticle: PropTypes.func,
  timeout: PropTypes.bool,
}
export default TransactionShareShort